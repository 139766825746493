import axios from './axios.min'
import {getDomain} from '@/config'
  var axiosClient = axios.create({})
  axiosClient.interceptors.request.use(function (config) {
    var token = MDM.getToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  axiosClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status === 401) {
      MDM.clear()
      MDM.login()
    }
    return Promise.reject(error);
  });

  var MDM = {
    cas: getDomain('cas') || process.env.VUE_APP_HOST_CAS,
    tc: getDomain('tc') || process.env.VUE_APP_HOST_TC,
    oc: getDomain('oc') || process.env.VUE_APP_HOST_OC,
    timer: null,
    getToken: function() {
      return sessionStorage.getItem('mdm_token')
    },
    setToken: function(token) {
      sessionStorage.setItem('mdm_token', token)
    },
    getOrderId: function() {
      return sessionStorage.getItem('mdm_order')
    },
    setOrderId: function(orderId) {
      sessionStorage.setItem('mdm_order', orderId)
    },
    clear: function() {
      MDM.clearToken()
      MDM.clearOrder()
    },
    clearToken: function() {
      sessionStorage.removeItem('mdm_token')
    },
    clearOrder: function() {
      sessionStorage.removeItem('mdm_order')
    },
    clearTimer() {
      clearInterval(MDM.timer)
    },
    login: function() {
      if (MDM.getToken()) {
        return
      }
      axiosClient.post(MDM.cas + '/api/tpass/token').then(function(res) {
        MDM.setToken(res.data.token)
      }).catch(function(err) {
        console.log(err)
      })
    },
    pay: function() {
      return axiosClient.post(MDM.tc + '/api/mdm_orders').then(function(res){
        MDM.setOrderId(res.data.id)
        return axiosClient.post(MDM.tc + '/api/mdm_orders/' + res.data.id + '/pay', {
          return_url: window.location.href
        })
      })
    },
    _check: function(id, succ, fail) {
      axiosClient.get(MDM.tc + '/api/mdm_orders/' + id, {
        headers: {'Authorization': MDM.getToken()}
      }).then(function(res) {
        if (res.data.status === 'COMPLETED') {
          MDM.clearTimer()
          if (succ) {
            succ(res.data)
          }
        } else if (res.data.status === 'EXPIRED') {
          MDM.clearTimer()
          if (fail) {
            fail()
          }
        }
      }).catch(function(err) {
        MDM.clearTimer()
        if (fail) {
          fail(err)
        }
      })
    },
    check: function(succ, fail) {
      var id = MDM.getOrderId()
      var times = 0
      if (id) {
        MDM.clearTimer()
        var times = 1
        MDM.timer = setInterval(function(){
          times += 1
          if (times > 8) {
            MDM.clearTimer()
            if (fail) {
              fail()
            }
            return
          }
          MDM._check(id, succ, fail)
        }, 5000)
        MDM._check(id, succ, fail)
      }
    },
    saveCode: function(code) {
      if (!code) { return }
      localStorage.setItem('mdm_code', code)
    },
    getLocalCode: function() {
      return localStorage.getItem('mdm_code')
    },
    reissueCode: function() {
      var mcode = MDM.getLocalCode()
      if (!mcode) { return }
      return axiosClient.post(MDM.oc + '/api/mcode/' + mcode + '/reissue').then(function(res) {
        if (res.data) {
          MDM.saveCode(res.data.code)
          MDM.clearOrder()
        }
      })
    },
  }

  var pay8 = document.getElementById('pay-8')
  var unpaid8 = document.getElementById('unpaid-8')
  var paid8 = document.getElementById('paid-8')

  function show(els , ele) {
    els.forEach(e => {
      if (ele === e) {
        e.style.display = 'block'
      } else {
        e.style.display = 'none'
      }
    })
  }

  var isPaying = false
  function paying () {
    if (isPaying) { return }
    isPaying = true
    var loading = new SpinLoading('body', {
      opacity: 0.8
    })
    loading.show()
    MDM.pay().then(function(res){
      window.open(res.data.url, '_self')
    }).catch(function(err) {
      loading.close()
      isPaying = false
      console.log(err)
    })
  }

  function changeIsPaying (status) {
    isPaying = status
  }

  function main(event, next, beforeFunc, successCallback, failCallback) {
    if (event.oldURL && event.oldURL.indexOf('#/ios')) {
      MDM.clearTimer()
    }
    var hash = window.location.hash
    if (hash.indexOf('#/ios') < 0) { return }
    var code = MDM.getLocalCode()
    if (code) {
      function showCode() {
        code = MDM.getLocalCode()
        next()
        // show(paid8)
        // document.getElementById('paid-8-result').innerText = code
      }
      var loading = new SpinLoading('body', {
        opacity: 0.8
      })
      loading.show()
      MDM.reissueCode().then(function() {
        loading.close()
        showCode()
      }).catch(function(err) {
        loading.close()
        showCode()
      })
      return
    }
    var token = MDM.getToken()
    if (!token) {
      MDM.login()
    }
    if (token && MDM.getOrderId()) {
      beforeFunc()
      pay8.innerText = '正在查询支付结果...'
      isPaying = true
      MDM.check(function(order){
        isPaying = false
        show(paid8)
        var code = order.mcode
        MDM.saveCode(code)
        successCallback()
        // document.getElementById('paid-8-result').innerText = code
      }, function(err) {
        setTimeout(function() {
          MDM.clearOrder()
          isPaying = false
        }, 1000)
          failCallback()
          pay8.innerText = '未查询到支付结果'
          show(unpaid8)
          // pay8.innerText = '支付8元'
      })
    }
  }
  // main()
  // window.addEventListener('DOMContentLoaded', main)
  // window.addEventListener('hashchange', main)


export {MDM, paying, changeIsPaying}
