// 对象合并
function extend (o, n, override) {
  for (var key in n) {
    if (n.hasOwnProperty(key) && (!o.hasOwnProperty(key) || override)) {
      o[key] = n[key]
    }
  }
  return o
}

function SpinLoading (elem, opt) {
  this._initial(elem, opt)
}

SpinLoading.prototype = {
  constructor: this,
  _initial: function (elem, opt) {
    // 默认参数
    var def = {
      text: '加载中...'
    }
    if (!opt) opt = {}
    this.def = extend(def, opt, true) //配置参数
    this.spinStatus = false
    this.removeHostDiv = false
    if (elem === 'body') {
      this.hostDiv = document.createElement('div')
      document.body.appendChild(this.hostDiv)
    } else {
      this.hostDiv = document.getElementById(elem)
    }
    this.spinContainer = this._generateTemplate()
    if (this.def && this.def.autoShow) {
      this.show()
    }
  },
  _generateTemplate: function () {
    var docContainer = document.createElement('div')
    var _innerHTML = '<div class="am-toast am-toast-mask"><span><div class="am-toast-notice am-toast-notice-closable"><div class="am-toast-notice-content"><div class="am-toast-text am-toast-text-icon" role="alert" aria-live="assertive"><svg class="am-icon am-icon-loading am-icon-lg" viewBox="0 -2 59.75 60.25"><path fill="#ccc" d="M29.69-.527C14.044-.527 1.36 12.158 1.36 27.806S14.043 56.14 29.69 56.14c15.65 0 28.334-12.686 28.334-28.334S45.34-.527 29.69-.527zm.185 53.75c-14.037 0-25.417-11.38-25.417-25.417S15.838 2.39 29.875 2.39s25.417 11.38 25.417 25.417-11.38 25.416-25.417 25.416z"/><path fill="none" stroke="#108ee9" stroke-width="3" stroke-linecap="round" stroke-miterlimit="10" d="M56.587 29.766c.37-7.438-1.658-14.7-6.393-19.552"/></svg><div class="am-toast-text-info"></div></div></div><a tabindex="0" class="am-toast-notice-close"><span class="am-toast-notice-close-x"></span></a></div></span></div>'
    docContainer.innerHTML = _innerHTML
    return docContainer
  },
  show: function () {
    if (!this.spinStatus) {
      this.spinStatus = true
      this.hostDiv.appendChild(this.spinContainer)
    }
  },
  close: function () {
    if (this.spinStatus) {
      this.spinStatus = false
      this.hostDiv.parentNode.removeChild(this.hostDiv)
    }
  }
}
// 最后将插件对象暴露给全局对象
window._global = (function () {
  return this || (0, eval)('this')
})()
if (typeof module !== 'undefined' && module.exports) {
  module.exports = SpinLoading
} else if (typeof define === 'function' && define.amd) {
  define(function () {
    return SpinLoading
  })
} else {
  !('SpinLoading' in _global) && (_global.SpinLoading = SpinLoading)
}
export default  SpinLoading
