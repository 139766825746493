<template>
  <div class="intro">
    <div>
      <div class="step-title">&nbsp;安装&nbsp;iOS&nbsp;版</div>
      <div class="step-box">
        <div>
          没有非中国大陆的 Apple ID ？
          <br>
          <br>
          <div class="step-info">
            <ul>
              <li>
                <span class="small-step-number">方法 1</span>
                <a :href="`/blog/ru-he-zhu-ce-ao-da-li-ya-apple-id/?from=${from}`">
                   注册澳大利亚 Apple ID
                </a>
              </li>
              <li >
                <span class="small-step-number" style="opacity: 0">方法 1</span>
                <span>
                  (只属于您，强烈推荐)
                </span>
              </li>
              <li>&nbsp;</li>
              <li>
                <span class="small-step-number">方法 2</span>
                <a :href="`/blog/ping-guo-gong-yong-apple-idshi-yong-jiao-cheng/?from=${from}`">
                   使用公用 Apple ID
                </a>
              </li>
            </ul>
          </div>

        </div>
        <hr>
        <div>
          有非中国大陆的 Apple ID，直接安装 <br> <br>
          <div @click="downloadInstall(['download-button-ios-appstore'], $event)"
               class="download-button download-button-ios-appstore app-store-like">
            <span>
              <img class="app-icon" src="@/assets/images/icon_app.png"/>
            </span>
            <span>
              <ul>
                <li class="app-title">狗急加速</li>
                <li class="app-intro">将跳转至AppStore</li>
                <li class="app-get">安装</li>
              </ul>
            </span>
          </div>
        </div>
      </div>
      <br>
      <div class="step-title" style="display: none;">
        付费安装企业版
      </div>
      <div ref="unpaid8" class="step-box" :style="unpaid8Style" id="unpaid-8">
        <ul>
          <li>付费安装，无需换区</li>
          <li>一次支付，永久升级</li>
        </ul>
        <br>
        <div ref="pay8" @click="paying" class="step-button" id="pay-8">
          支付 8 元
        </div>
        <br><br>
        <ul class="step-comment">
          <li>• 仅为安装费用，未包含使用套餐</li>
          <li>• 仅限本机使用，安装费用不退款</li>
        </ul>
        <hr>
        <div class="app-store-like">
          <span>
            <img class="app-icon" src="@/assets/images/icon_app.png"/>
          </span>
          <span>
            <ul>
              <li class="app-title">狗急加速</li>
              <li class="app-intro">将下载安装文件</li>
              <li class="app-get unpaid">支付后安装</li>
            </ul>
          </span>
        </div>
      </div>
      <div ref="paid8" class="step-box" :style="paid8Style" id="paid-8">
        <div>
          &nbsp;&nbsp;<span><img class="paid-icon" src="@/assets/images/pay_success.png"></span>
          <span class="mdm-redeem-code" ref="paid8result" id="paid-8-result">ABCDE</span>&nbsp;&nbsp;
          <span id="mdm-redeem-code-cp" @click="copy">复制</span>
        </div>
        <br>
        <div>
          下载码在安装过程中使用<br>
          遇到问题请咨询客服
        </div>
        <br>
        <ul class="step-comment">
          <li>• 仅为安装费用，未包含使用套餐</li>
          <li>• 仅限本机使用，安装费用不退款</li>
        </ul>
        <hr>
        <div @click="downloadInstall(['download-button-ios-mdm'], $event)"
             class="download-button download-button-ios-mdm app-store-like">
          <span>
            <img class="app-icon" src="@/assets/images/icon_app.png"/>
          </span>
          <span>
            <ul>
              <li class="app-title">狗急加速</li>
              <li class="app-intro">将下载安装文件</li>
              <li class="app-get">快速安装</li>
            </ul>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { onMounted, reactive, ref } from 'vue'
  import utils from "../utils/utils"
  import SpinLoading from "../common/loading.js"
  import { changeIsPaying, MDM, paying } from "../common/topup.js"

  export default {
    name: 'IOS',
    components: {},
    props: {
      isFixed: {
        type: Boolean,
        default: false
      }
    },
    setup () {
      let stores = useStore()
      let store = stores.state

      let from = ref(utils.getQuery('from') || 'index')
      if (window.env && window.env.channel) from.value = window.env.channel
      if (window.location.href.includes('index')) from.value = 'index'

      function downloadInstall (className, event) {
        utils.statsDownload("ios")
        stores.commit('changeToPath', {tag: 'toIosAppStoreFaq', value: true})
        let resp = window.install('', className)
        if (!resp) event.preventDefault()
        return resp
      }

      let paid8result = ref(null)
      let paid8 = ref(null)
      let paid8Style = reactive({
        display: 'none'
      })
      let unpaid8 = ref(null)
      let unpaid8Style = reactive({
        display: 'none'
      })
      let pay8 = ref(null)

      function copy () {
        utils.copy(paid8result.value.innerText)
      }

      function topupMain () {
        // if (event.oldURL && event.oldURL.indexOf('#/ios')) {
        //   MDM.clearTimer()
        // }
        var code = MDM.getLocalCode()
        if (code) {
          function showCode () {
            code = MDM.getLocalCode()
            paid8Style.display = 'block'
            paid8result.value.innerText = code
          }

          var loading = new SpinLoading('body', {
            opacity: 0.8
          })
          loading.show()
          MDM.reissueCode().then(function () {
            loading.close()
            showCode()
          }).catch(function (err) {
            loading.close()
            showCode()
          })
          return
        }
        var token = MDM.getToken()
        if (!token) {
          MDM.login()
        }
        if (token && MDM.getOrderId()) {
          pay8.value.innerText = '正在查询支付结果...'
          changeIsPaying(true)
          MDM.check(function (order) {
            changeIsPaying(false)
            paid8Style.display = 'block'
            var code = order.mcode
            MDM.saveCode(code)
            paid8result.value.innerText = code
          }, function (err) {
            pay8.value.innerText = '未查询到支付结果'
            setTimeout(function () {
              MDM.clearOrder()
              changeIsPaying(false)
              unpaid8Style.display = 'block'
              pay8.value.innerText = '支付8元'
            }, 1000)
          })
        }
      }


      onMounted(() => {
        topupMain()
      })

      return {
        paid8result,
        paid8,
        unpaid8,
        paid8Style,
        unpaid8Style,
        pay8,
        paying,
        store,
        copy,
        downloadInstall,
        from
      }
    },
  }
</script>

<style scoped>

  .intro .app-store-like .app-get, .intro .step-button, .intro a.step-button {
    background-color: #06f;
    border-radius: 20rem;
    border: none;
    color: #fff;
    padding: 0.2rem 1.2rem;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
  }

  .mdm-redeem-code {
    font-size: 1.6rem;
  }

  #mdm-redeem-code-cp {
    color: #0066FF;
  }

  .paid-icon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.5rem;
    display: block;
  }

  .intro .app-store-like .app-get.unpaid {
    background-color: #b2b2b2;
  }
  .intro .step-box .step-info {
    display: flex;
    justify-content: center;
  }
  .intro .step-box .step-info ul {
    text-align: left;
  }
  .intro .small-step-number{
    background-color: black;
    color: white;
    border-radius: 10rem;
    border: none;
    width: auto;
    height: auto;
    line-height: 1.6rem;
    font-size: 1.2rem;
    text-align: center;
    vertical-align: top;
    padding: 1px 8px;
  }
  .intro .step-box .step-info a{
    margin-left: 4px;
  }
</style>
